import * as THREE from 'three'
import Experience from '../Experience.js'
import vertexShader from '../../Shaders/WaterSurface/vertex.glsl'
import fragmentShader from '../../Shaders/WaterSurface/fragment.glsl'

import { Side } from 'three'



export default class WaterSurface {

    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.debug = this.experience.debug
        this.sizes = this.experience.sizes

        if(this.debug.active)
        {
           this.debugFolder = this.debug.ui.addFolder('WaterSurface')
        }
        
         // Setup
         this.setGeometry()
         this.setMaterial()
         this.setMesh()
    }
    
    setGeometry() {
        this.geometry = new THREE.PlaneGeometry(4, 4, 512, 512)
    }

    setMaterial() {

        const debugObject = {}

        debugObject.depthColor = '#000000'
        debugObject.surfaceColor = '#118ad4'
        
        if(this.debug.active) {
            this.debugFolder.addColor(debugObject, 'depthColor').onChange(() => { this.material.uniforms.uDepthColor.value.set(debugObject.depthColor) })
            this.debugFolder.addColor(debugObject, 'surfaceColor').onChange(() => { this.material.uniforms.uSurfaceColor.value.set(debugObject.surfaceColor) })
        }
        

        this.material = new THREE.ShaderMaterial({
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            uniforms:
            {
                uTime: { value: 0 },
                
                uBigWavesElevation: { value: 0.03 },
                uBigWavesFrequency: { value: new THREE.Vector2(4, 1.5) },
                uBigWavesSpeed: { value: 0.75 },
        
                uSmallWavesElevation: { value: 0.15 },
                uSmallWavesFrequency: { value: 3 },
                uSmallWavesSpeed: { value: 0.2 },
                uSmallIterations: { value: 4 },
        
                uDepthColor: { value: new THREE.Color(debugObject.depthColor) },
                uSurfaceColor: { value: new THREE.Color(debugObject.surfaceColor) },
                uColorOffset: { value: 0.08 },
                uColorMultiplier: { value: 5 }, 
                uAlpha: { value: 0.5 },
            },
            side: THREE.BackSide,
            transparent: true,
            blending: THREE.AdditiveBlending,
        //    transparent: true, 
        //    opacity: 0.5,
            
        })


      



    }

    setMesh() {
        this.mesh = new THREE.Mesh(this.geometry, this.material)
        this.mesh.rotation.x = - Math.PI * 0.45
        this.mesh.rotation.y =  Math.PI * 0.07

        //Positions & Responsiveness
        if(this.experience.sizes.width < 991) {
            this.mesh.position.set(0.7, 0.42, 7.2)
        } else {
            this.mesh.position.set(1, 0.45, 7.2)
        }

        
        
        // console.log(this.sizes)
        // this.mesh.center.set(0.0, 0.0)
        // this.mesh.position.set(this.sizes.width, this.sizes.height, 7.2)
        this.scene.add(this.mesh)


        // Debug
        if(this.debug.active)
        {
            this.debugFolder.add(this.material.uniforms.uBigWavesElevation, 'value').min(0).max(1).step(0.001).name('uBigWavesElevation')
            this.debugFolder.add(this.material.uniforms.uBigWavesFrequency.value, 'x').min(0).max(10).step(0.001).name('uBigWavesFrequencyX')
            this.debugFolder.add(this.material.uniforms.uBigWavesFrequency.value, 'y').min(0).max(10).step(0.001).name('uBigWavesFrequencyY')
            this.debugFolder.add(this.material.uniforms.uBigWavesSpeed, 'value').min(0).max(4).step(0.001).name('uBigWavesSpeed')
    
    
            this.debugFolder.add(this.material.uniforms.uSmallWavesElevation, 'value').min(0).max(1).step(0.001).name('uSmallWavesElevation')
            this.debugFolder.add(this.material.uniforms.uSmallWavesFrequency, 'value').min(0).max(30).step(0.001).name('uSmallWavesFrequency')
            this.debugFolder.add(this.material.uniforms.uSmallWavesSpeed, 'value').min(0).max(4).step(0.001).name('uSmallWavesSpeed')
            this.debugFolder.add(this.material.uniforms.uSmallIterations, 'value').min(0).max(5).step(1).name('uSmallIterations')
    
            this.debugFolder.add(this.material.uniforms.uColorOffset, 'value').min(0).max(1).step(0.001).name('uColorOffset')
            this.debugFolder.add(this.material.uniforms.uColorMultiplier, 'value').min(0).max(10).step(0.001).name('uColorMultiplier')

     

            
            this.debugFolder
                .add(this.mesh.position, 'x')
                .name('SurfaceX')
                .min(- 10)
                .max(10)
                .step(0.001)

                this.debugFolder
                .add(this.mesh.position, 'y')
                .name('SurfaceY')
                .min(- 10)
                .max(10)
                .step(0.001)

                this.debugFolder
                .add(this.mesh.position, 'z')
                .name('SurfaceZ')
                .min(- 10)
                .max(10)
                .step(0.001)

        }
    }

    update() {
        this.mesh.material.uniforms.uTime.value = this.experience.time.elapsed * 0.0005
    }


}