import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import EventEmitter from './EventEmitter.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'

export default class Resources extends EventEmitter
{
    constructor(sources)
    {
        super()

        // Options
        this.sources = sources

        // Setup
        this.items = {}
        this.toLoad = this.sources.length
        this.loaded = 0

        this.setLoaders()
        this.startLoading()
    }


    setLoaders()
    {

       

        this.loaders = {}
        this.loaders.gltfLoader = new GLTFLoader()
        this.loaders.textureLoader = new THREE.TextureLoader()
        this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader()
        this.loaders.dracoLoader = new DRACOLoader()

    }


    startLoading()
    {
        // Load each source
        for(const source of this.sources)
        {
            
            if(source.type === 'gltfModel')
            {
                const dracoLoader = new DRACOLoader()
                dracoLoader.setDecoderPath('draco/')
                dracoLoader.setDecoderConfig({ type: 'js' })
                this.loaders.gltfLoader.setDRACOLoader(dracoLoader)
                this.loaders.gltfLoader.load(
                    source.path,
                    (file) =>
                    {
                        this.sourceLoaded(source, file)
                    }
                )
                
            }
            else if(source.type === 'texture')
            {
                this.loaders.textureLoader.load(
                    source.path,
                    (file) =>
                    {
                        this.sourceLoaded(source, file)
                    }
                )
            }
            else if(source.type === 'cubeTexture')
            {
                this.loaders.cubeTextureLoader.load(
                    source.path,
                    (file) =>
                    {
                        this.sourceLoaded(source, file)
                    }
                )
            }
        }

    }


    sourceLoaded(source, file)
    {
        
        // Trigger progress
        
        
        this.trigger('progress', [this.loaded / this.toLoad])
        
        this.items[source.name] = file

        this.loaded++

        //console.log((this.loaded/this.toLoad))

        if(this.loaded === this.toLoad)
        {   
           
            //console.log('All resources loaded')

            this.trigger('ready')
        }
    }



}