import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Environment
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.debug = this.experience.debug

         // Debug
         if(this.debug.active)
         {
            this.debugFolder = this.debug.ui.addFolder('environment')
         }

        // Setup
        this.setSunLight()
        //this.setEnvironmentMap()
        
    }

    setSunLight() 
    {
        this.sunLight = new THREE.DirectionalLight('#9BA2B0', 0.25)
        this.sunLight.castShadow = true
        this.sunLight.shadow.camera.far = 15
        this.sunLight.shadow.mapSize.set(1024, 1024)
        this.sunLight.shadow.normalBias = 0.05
        this.sunLight.position.set(10.0, 5.5, -10.0)
        //this.sunLight.rotation.x = Math.PI * 0.25
        this.sunLight.name = 'sunLight'
         this.scene.add(this.sunLight)
        //console.log(this.sunLight)

        this.bottomLight = new THREE.DirectionalLight('#9BA2B0', 0.25)
        this.bottomLight.position.set(8, -5, 0)
         this.scene.add(this.bottomLight)

        this.topLight = new THREE.DirectionalLight('#9BA2B0', 2)
        this.topLight.position.set(1, 4.0, 1)
        this.scene.add(this.topLight)
        
        // this.helper = new THREE.DirectionalLightHelper( this.topLight, 4 );
        // this.scene.add( this.helper );

    // Debug
    if(this.debug.active)
    {

        this.debugFolder
            .addColor(this.sunLight, 'color')

        this.debugFolder
            .add(this.sunLight, 'intensity')
            .name('sunLightIntensity')
            .min(0)
            .max(10)
            .step(0.001)
        
        this.debugFolder
            .add(this.sunLight.position, 'x')
            .name('sunLightX')
            .min(- 10)
            .max(10)
            .step(0.001)
        
        this.debugFolder
            .add(this.sunLight.position, 'y')
            .name('sunLightY')
            .min(- 10)
            .max(10)
            .step(0.001)
        
        this.debugFolder
            .add(this.sunLight.position, 'z')
            .name('sunLightZ')
            .min(- 10)
            .max(10)
            .step(0.001)

            this.debugFolder
            .add(this.sunLight.rotation, 'x')
            .name('sunLightRotZ')
            .min(- 10)
            .max(10)
            .step(0.001)

            this.debugFolder
            .add(this.sunLight.rotation, 'y')
            .name('sunLightRotZ')
            .min(- 10)
            .max(10)
            .step(0.001)

            this.debugFolder
            .add(this.sunLight.rotation, 'z')
            .name('sunLightRotZ')
            .min(- 10)
            .max(10)
            .step(0.001)
    }

        
    }

    setEnvironmentMap()
    {
        this.environmentMap = {}
        this.environmentMap.intensity = 0.4
        this.environmentMap.texture = this.resources.items.environmentMapTexture
        this.environmentMap.texture.encoding = THREE.sRGBEncoding
        
        this.scene.environment = this.environmentMap.texture

        this.setEnvironmentMap.updateMaterials = () => 
        {
            this.scene.traverse((child) => {
                if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
                {
                    child.material.envMap = this.environmentMap.texture
                    child.material.envMapIntensity = this.environmentMap.intensity
                    child.material.needsUpdate = true
                }
            })
        }

        this.setEnvironmentMap.updateMaterials()

        // Debug
        if(this.debug.active)
        {
            this.debugFolder
                .add(this.environmentMap, 'intensity')
                .name('envMapIntensity')
                .min(0)
                .max(4)
                .step(0.001)
                .onChange(this.setEnvironmentMap.updateMaterials)
        }
        
    }

    


}