import * as THREE from 'three'
import Experience from './Experience.js'
import { GodRaysEffect, EffectComposer, EffectPass, BloomEffect, RenderPass, KernelSize, ShaderPass, DotScreenEffect, BlendFunction, GlitchEffect, SMAAEffect } from "postprocessing";
// import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
// import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
// import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
//import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass.js';
import vertexShader from '../Shaders/Fish/vertex.glsl'
import fragmentShader from '../Shaders/Fish/fragment.glsl'
import { GPUComputationRenderer } from 'three/examples/jsm/misc/GPUComputationRenderer.js'
import positionfragmentShader from '../Shaders/Fish/fragmentPosition.glsl'
import velocityfragmentShader from '../Shaders/Fish/fragmentVelocity.glsl'
import FishGeometry from './World/FishGeometry.js'




export default class Renderer
{
    constructor()
    {
        this.experience = new Experience()
        this.canvas = this.experience.canvas
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.camera = this.experience.camera
        this.debug = this.experience.debug
        this.FishGeometry = new FishGeometry()

         // Debug
         if(this.debug.active)
         {
            this.debugFolder = this.debug.ui.addFolder('GodRays')
         }

         this.setInstance()
         this.setInstancePostProcessing()
        //  this.setComputeRenderer() 
        //  this.setFishes()
         
         //this.last = performance.now()
         

         
                // /**
                // * Cursor
                // */
                //  this.cursor = {}
                //  this.cursor.x = 0
                //  this.cursor.y = 0
                //  this.windowHalfX = this.experience.sizes.width / 2
                //  this.windowHalfY = this.experience.sizes.height / 2
                //  window.addEventListener('mousemove', (event) =>
                //  {
                //      this.cursor.x = (event.clientX - this.windowHalfX )
                //      this.cursor.y = (event.clientY - this.windowHalfY )
                    
                //  })


    }


 


    //Set Renderer
    setInstance() 
    {
        this.instance = new THREE.WebGLRenderer({
            canvas: this.canvas,
            powerPreference: "high-performance",
            antialias: false,
            stencil: false,
            depth: false
            //aplha: true,

        })
        // this.instance.physicallyCorrectLights = true
        // this.instance.outputEncoding = THREE.sRGBEncoding
        // this.instance.toneMapping = THREE.CineonToneMapping
        // this.instance.toneMappingExposure = 1.75
        // this.instance.shadowMap.enabled = true
        // this.instance.shadowMap.type = THREE.PCFSoftShadowMap
        this.instance.setClearColor('#004080')
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2))
        this.instance.autoClear = false
    }

    // Post Processing Passes (God Rays)
    setInstancePostProcessing() 
    {
        
        this.passes = {}

        this.passes.composer = new EffectComposer(this.instance)
        
        this.passes.renderPass = new RenderPass(this.scene, this.camera.instance)

        
        this.sunMaterial = new THREE.MeshBasicMaterial({ color: new THREE.Color('#9BADBB'), fog: false });
        this.sunGeometry = new THREE.SphereGeometry(8, 16, 16);

	    this.sun = new THREE.Mesh(this.sunGeometry, this.sunMaterial); 
        
        //Positions & Responsiveness
        if(this.experience.sizes.width < 991) {
            this.sun.position.set(4, 9.5, 0);
        } else {
            this.sun.position.set(5, 9.5, 0)
        }


        
	    this.sun.frustumCulled = false;      
        //this.scene.add(this.sun);    
        this.sun.matrixAutoUpdate = false;
        this.sun.updateMatrix();

        this.passes.GodRay = new GodRaysEffect(this.camera.instance, this.sun, {
            kernelSize: KernelSize.SMALL,
            height: 480,
            density: 0.96,
            decay: 0.92,
            weight: 0.3,
            exposure: 0.54,
            samples: 60,
            clampMax: 1.0,
            blur: true,

        })
        this.effect = this.passes.GodRay;
        this.effect.blurPass.kernelSize = 4;
       
        const color = new THREE.Color();
        const blendMode = this.effect.blendMode;
        const uniforms = this.effect.godRaysMaterial.uniforms;
        this.effect.blendMode.opacity.value = 0.9;

        const params = {
            "resolution": this.effect.height,
            "blurriness": this.effect.blurPass.kernelSize + 1,
            "density": uniforms.density.value,
			"decay": uniforms.decay.value,
			"weight": uniforms.weight.value,
			"exposure": uniforms.exposure.value,
			"clampMax": uniforms.clampMax.value,
			//"samples": this.effect.samples,
			"color": color.copyLinearToSRGB(this.sun.material.color).getHex(),
			"opacity": blendMode.opacity.value,
			"blend mode": blendMode.blendFunction
        }

        this.passes.SMAA = new SMAAEffect();
        
        this.passes.composer.addPass(this.passes.renderPass)
        this.passes.composer.addPass(new EffectPass(this.camera.instance ,this.passes.GodRay))
        //Anti Aliasing
        this.passes.composer.addPass(new EffectPass(this.camera.instance , this.passes.SMAA))

        //Glitch Pass Example
        //this.passes.glitchPass = new GlitchEffect()
        //this.passes.composer.addPass(new EffectPass(this.camera.instance, this.passes.glitchPass))

        //Debug
        if(this.debug.active)
        {
            const effect = this.effect;
            const uniforms = effect.godRaysMaterial.uniforms;
            const blendMode = this.effect.blendMode;

            this.debugFolder.add(this.passes.GodRay, 'height', 0, 1000, 1).name('Height')
            this.debugFolder.add(this.passes.GodRay, 'samples', 0, 200).name('Samples')
            this.debugFolder.add(params, "resolution", [240, 360, 480, 720, 1080])
			.onChange((value) => {
				effect.resolution.height = Number(value);
			});

            this.debugFolder.add(params, "blurriness",
			KernelSize.VERY_SMALL, KernelSize.HUGE + 1, 1).onChange((value) => {
			effect.blur = (value > 0);
			effect.blurPass.kernelSize = value - 1;
            });


            this.debugFolder.add(params, "density", 0.0, 1.0, 0.01).onChange((value) => {
                uniforms.density.value = value;
            });

            this.debugFolder.add(params, "decay", 0.0, 1.0, 0.01).onChange((value) => {
                uniforms.decay.value = value;
            });


            this.debugFolder.add(params, "weight", 0.0, 1.0, 0.01).onChange((value) => {
                uniforms.weight.value = value;
            });
    
            this.debugFolder.add(params, "exposure", 0.0, 1.0, 0.01).onChange((value) => {
                uniforms.exposure.value = value;
            });
    
            this.debugFolder.add(params, "clampMax", 0.0, 1.0, 0.01).onChange((value) => {
                uniforms.clampMax.value = value;
            });

            this.debugFolder.addColor(params, "color").onChange((value) => {
                this.sun.material.color.setHex(value).convertSRGBToLinear();
                //Find out why the light isnt working then import it so it can be used here
                //light.color.setHex(value).convertSRGBToLinear();
            });
    
            this.debugFolder.add(params, "opacity", 0.0, 1.0, 0.01).onChange((value) => {
                blendMode.opacity.value = value;
            });
    
            this.debugFolder.add(params, "blend mode", BlendFunction).onChange((value) => {
                blendMode.setBlendFunction(Number(value));
            });

            this.debugFolder
            .add(this.sun.position, 'x')
            .name('SunX')
            .min(- 10)
            .max(10)
            .step(0.001)

            this.debugFolder
            .add(this.sun.position, 'y')
            .name('SunY')
            .min(- 10)
            .max(10)
            .step(0.001)

            this.debugFolder
            .add(this.sun.position, 'z')
            .name('SunZ')
            .min(- 10)
            .max(10)
            .step(0.001)

        }

        


    }



//     setFishes() {

//         this.birdUniforms = {
//             'color': { value: new THREE.Color( 0xff00ff ) },
//             'texturePosition': { value: null },
//             'textureVelocity': { value: null },
//             'time': { value: 1.0 },
//             'delta': { value: 0.0 }
//         };

//         this.birdsMaterial = new THREE.ShaderMaterial( {
//             uniforms: this.birdUniforms,
//             vertexShader: vertexShader,
//             fragmentShader: fragmentShader,
//             side: THREE.DoubleSide, 

//         } )

//         this.birdMesh = new THREE.Mesh( this.FishGeometry, this.birdsMaterial )
//         //this.particles = new THREE.Points(this.particlesGeometry, this.particlematerial)
//         //this.birdMesh.position.set(0, 0, 0)
//         this.birdMesh.rotation.y = Math.PI / 2;
//         this.birdMesh.matrixAutoUpdate = false;
//         this.birdMesh.updateMatrix();
//         this.scene.add(this.birdMesh)
// }


// setComputeRenderer() {
    

// function fillPositionTexture( texture ) {

//     const theArray = texture.image.data;

//     for ( let k = 0, kl = theArray.length; k < kl; k += 4 ) {

//         const x = Math.random() * BOUNDS - BOUNDS_HALF;
//         const y = Math.random() * BOUNDS - BOUNDS_HALF;
//         const z = Math.random() * BOUNDS - BOUNDS_HALF;

//         theArray[ k + 0 ] = x;
//         theArray[ k + 1 ] = y;
//         theArray[ k + 2 ] = z;
//         theArray[ k + 3 ] = 1;

//     }

// }

// function fillVelocityTexture( texture ) {

//     const theArray = texture.image.data;

//     for ( let k = 0, kl = theArray.length; k < kl; k += 4 ) {

//         const x = Math.random() - 0.5;
//         const y = Math.random() - 0.5;
//         const z = Math.random() - 0.5;

//         theArray[ k + 0 ] = x * 10;
//         theArray[ k + 1 ] = y * 10;
//         theArray[ k + 2 ] = z * 10;
//         theArray[ k + 3 ] = 1;

//     }

// }

// const BOUNDS = 800, BOUNDS_HALF = BOUNDS / 2;
// //let gpuCompute;
// //let velocityVariable;
// //let positionVariable;
// //let positionUniforms;
// //let velocityUniforms;
// //console.log(this.renderer.instance)
// //console.log(this.experience.renderer)
// this.gpuCompute = new GPUComputationRenderer(32, 32, this.instance );

//         if ( this.instance.capabilities.isWebGL2 === false ) {

//             this.gpuCompute.setDataType( THREE.HalfFloatType );

//         }
        
//         this.dtPosition = this.gpuCompute.createTexture();
//         this.dtVelocity = this.gpuCompute.createTexture();
//         fillPositionTexture( this.dtPosition );
//         fillVelocityTexture( this.dtVelocity );

//         this.velocityVariable = this.gpuCompute.addVariable( 'textureVelocity', velocityfragmentShader, this.dtVelocity );
//         this.positionVariable = this.gpuCompute.addVariable( 'texturePosition', positionfragmentShader, this.dtPosition );

//         this.gpuCompute.setVariableDependencies( this.velocityVariable, [ this.positionVariable, this.velocityVariable ] );
//         this.gpuCompute.setVariableDependencies( this.positionVariable, [ this.positionVariable, this.velocityVariable ] );

//         this.positionUniforms = this.positionVariable.material.uniforms;
//         this.velocityUniforms = this.velocityVariable.material.uniforms;

//         this.positionUniforms[ 'time' ] = { value: 0.0 };
//         this.positionUniforms[ 'delta' ] = { value: 0.0 };
//         this.velocityUniforms[ 'time' ] = { value: 1.0 };
//         this.velocityUniforms[ 'delta' ] = { value: 0.0 };
//         this.velocityUniforms[ 'testing' ] = { value: 1.0 };
//         this.velocityUniforms[ 'separationDistance' ] = { value: 0.5 };
//         this.velocityUniforms[ 'alignmentDistance' ] = { value: 100.0 };
//         this.velocityUniforms[ 'cohesionDistance' ] = { value: 100.0 };
//         this.velocityUniforms[ 'freedomFactor' ] = { value: 0.75 };
//         this.velocityUniforms[ 'predator' ] = { value: new THREE.Vector3() };
//         this.velocityVariable.material.defines.BOUNDS = BOUNDS.toFixed( 2 );
        
//         this.velocityVariable.wrapS = THREE.RepeatWrapping;
//         this.velocityVariable.wrapT = THREE.RepeatWrapping;
//         this.positionVariable.wrapS = THREE.RepeatWrapping;
//         this.positionVariable.wrapT = THREE.RepeatWrapping;

//         const error = this.gpuCompute.init();

//         if ( error !== null ) {

//             console.error( error );

//         }

//         const effectController = {
//             separation: 1.0,
//             alignment: 1.0,
//             cohesion: 20.0,
//             freedom: 0.75
//         };

//         // const valuesChanger = function () {

//         // 	this.velocityUniforms[ 'separationDistance' ].value = effectController.separation;
//         // 	this.velocityUniforms[ 'alignmentDistance' ].value = effectController.alignment;
//         // 	this.velocityUniforms[ 'cohesionDistance' ].value = effectController.cohesion;
//         // 	this.velocityUniforms[ 'freedomFactor' ].value = effectController.freedom;

//         // };

//         //valuesChanger();

// }


    resize()
    {
        this.instance.setSize(this.sizes.width, this.sizes.height)
        this.passes.composer.setSize(this.sizes.width, this.sizes.height)
    }

    update()
    {
        this.passes.composer.render()

        // const now = performance.now();
        // let delta = ( now - this.last ) / 1000;
 
        // if ( delta > 1 ) delta = 1; // safety cap on large deltas
        // this.last = now;
 
        // this.positionUniforms[ 'time' ].value = now * 0.5;
        // this.positionUniforms[ 'delta' ].value = delta * 0.5;
        // this.velocityUniforms[ 'time' ].value = now;
        // this.velocityUniforms[ 'delta' ].value = delta;
        // this.birdUniforms[ 'time' ].value = now;
        // this.birdUniforms[ 'delta' ].value = delta;
 
        // this.velocityUniforms[ 'predator' ].value.set( 0.5 * this.cursor.x / this.windowHalfX, - 0.5 * this.cursor.y / this.windowHalfY, 0 );
        
        // this.cursor.x = 10000;
		// this.cursor.y = 10000;


        // this.gpuCompute.compute()
 
        
 
        // this.birdUniforms[ 'texturePosition' ].value = this.gpuCompute.getCurrentRenderTarget( this.positionVariable ).texture;
        // this.birdUniforms[ 'textureVelocity' ].value = this.gpuCompute.getCurrentRenderTarget( this.velocityVariable ).texture;

    }


    



}