import * as THREE from 'three'
import Experience from '../Experience.js'
import gsap from 'gsap'
import * as SkeletonUtils from 'three/examples/jsm/utils/SkeletonUtils.js'

export default class Shark
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug


        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('Shark')
        }

        // Setup
        this.resource = this.resources.items.sharkModel

        this.setModel()
        this.setAnimation()
    }


    setModel()  
    {
        this.model = this.resource.scene
        this.model2 = SkeletonUtils.clone(this.model)
        this.model3 = SkeletonUtils.clone(this.model)



        //Positions & Responsiveness
        if(this.experience.sizes.width < 991) {
            

            this.model.position.set(-20, 2.75, -12)
            this.model2.position.set(-20, 2.25, -12)
            this.model3.position.set(-21, 2.5, -12)

            gsap.to(this.model.position, { duration: 30, x: 25, y: 3, z: -12, ease: 'none', onComplete: () => {
                gsap.fromTo(this.model.position, {  x: -20, y: 3, z: -12,}, { duration: 30, x: 25, y: 3, z: -12, repeat: -1, ease: 'none', })
             } })

             gsap.to(this.model2.position, { duration: 30, x: 25, y: 2, z: -12, ease: 'none', onComplete: () => {
                gsap.fromTo(this.model2.position, {  x: -20, y: 2, z: -12,}, { duration: 30, x: 25, y: 2, z: -12, repeat: -1, ease: 'none', })
             } })

             gsap.to(this.model3.position, { duration: 30, x: 25, y: 2.5, z: -12, ease: 'none', onComplete: () => {
                gsap.fromTo(this.model3.position, {  x: -21, y: 2.5, z: -12,}, { duration: 30, x: 25, y: 2.5, z: -12, repeat: -1, ease: 'none', })
             } })


        } else {
            this.model.position.set(-20, 2.75, -12)
            this.model2.position.set(-20, 2.25, -12)
            this.model3.position.set(-21, 2.5, -12)

            gsap.to(this.model.position, { duration: 30, x: 25, y: 3, z: -12, ease: 'none', onComplete: () => {
                gsap.fromTo(this.model.position, {  x: -20, y: 3, z: -12,}, { duration: 30, x: 25, y: 3, z: -12, repeat: -1, ease: 'none', })
             } })

             gsap.to(this.model2.position, { duration: 30, x: 25, y: 2, z: -12, ease: 'none', onComplete: () => {
                gsap.fromTo(this.model2.position, {  x: -20, y: 2, z: -12,}, { duration: 30, x: 25, y: 2, z: -12, repeat: -1, ease: 'none', })
             } })

             gsap.to(this.model3.position, { duration: 30, x: 25, y: 2.5, z: -12, ease: 'none', onComplete: () => {
                gsap.fromTo(this.model3.position, {  x: -21, y: 2.5, z: -12,}, { duration: 30, x: 25, y: 2.5, z: -12, repeat: -1, ease: 'none', })
             } })

             
             
        }

        this.model.scale.set(0.08, 0.08, 0.08)
        this.model.rotation.y = Math.PI * 0.5

        this.model2.scale.set(0.08, 0.08, 0.08)
        this.model2.rotation.y = Math.PI * 0.5
       
        this.model3.scale.set(0.08, 0.08, 0.08)
        this.model3.rotation.y = Math.PI * 0.5
       

        this.scene.add(this.model, this.model2, this.model3)


        this.model.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                child.castShadow = true
                //10 percent chance
                if(Math.random() < 0.1) {
                    child.material.map = null
                }
                
                //console.log(child)
            }
        })


        

    }

    setAnimation()
    {
        this.animation = {}
        
        this.animation.mixer = new THREE.AnimationMixer(this.model)
        this.animation.actions = {}
        //console.log(this.resource.animations)
        this.animation.actions.swim = this.animation.mixer.clipAction(this.resource.animations[1])
        // this.animation.actions.walking = this.animation.mixer.clipAction(this.resource.animations[1])
        // this.animation.actions.running = this.animation.mixer.clipAction(this.resource.animations[2])

        this.animation.actions.current = this.animation.actions.swim
        this.animation.actions.current.play()

        this.animation.play = (name) =>
        {
            const newAction = this.animation.actions[name]
            const oldAction = this.animation.actions.current

            newAction.reset()
            newAction.play()
            newAction.crossFadeFrom(oldAction, 1)

            this.animation.actions.current = newAction
        }

        if(this.debug.active)
        {
            const debugObject = {
                playSwim: () => { this.animation.play('swimming') },
                // playWalking: () => { this.animation.play('walking') },
                // playRunning: () => { this.animation.play('running') }
            }
            this.debugFolder.add(debugObject, 'playSwim')
            // this.debugFolder.add(debugObject, 'playWalking')
            // this.debugFolder.add(debugObject, 'playRunning')
        }


    }

    update() {
        this.animation.mixer.update(this.time.delta * 0.0005)
        // this.model.position.x -= 0.005
        // this.model.position.z -= 0.005
         //this.model.position.y = Math.sin(this.time.elapsed * 0.0002) * 0.25
        // console.log(Math.sin(this.time.elapsed * 0.0001) * 0.1) * 0.25
        //console.log(this.time)
        
    }

}