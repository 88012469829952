import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Water {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.debug = this.experience.debug

        
    
        if(this.debug.active)
        {
           this.debugFolder = this.debug.ui.addFolder('Water')
        }

        this.setWater()
        this.setFog()
    }

    setFog() {
        this.scene.fog = new THREE.Fog('#004080', 2, 25)

        // Debug
        if(this.debug.active)
        {
            this.debugFolder
                .add(this.scene.fog, 'near')
                .name('fogNear')
                .min(0)
                .max(25)
                .step(0.001)

            this.debugFolder
                .add(this.scene.fog, 'far')
                .name('fogFar')
                .min(0)
                .max(25)
                .step(0.001)
        }
    }

    setWater() {
        
        // Creates a sphere that handles the gradient water effect
        // because of the fog colour it doesnt look too great when someone goes out of distance

            const  canvastest = document.createElement( 'canvas' );
			canvastest.width = 1;
			canvastest.height = 32;

            const context = canvastest.getContext( '2d' );
            const gradient = context.createLinearGradient( 0, 0, 0, 32 );
            gradient.addColorStop( 0.0, '#1ebaf9' );
            gradient.addColorStop( 0.5, '#003e7f' );
            gradient.addColorStop( 1.0, '#021c33' );

            //gradient.addColorStop( 0.0, '#15b1f7' );
            //gradient.addColorStop( 0.5, '#004b9c' );
            //gradient.addColorStop( 1.0, '#001226' );
            
            context.fillStyle = gradient;
            context.fillRect( 0, 0, 1, 32 );

            const water = new THREE.Mesh(
                new THREE.SphereGeometry( 30 ),
                new THREE.MeshBasicMaterial( { map: new THREE.CanvasTexture( canvastest ), side: THREE.BackSide } )
            );
            water.material.fog = false
            this.scene.add( water );
    }

    

}
