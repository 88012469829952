import * as THREE from 'three'
import Experience from '../Experience.js'
import gsap from 'gsap'


export default class Fox
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug


        // Debug
        if(this.debug.active)
        {
            this.debugFolder = this.debug.ui.addFolder('fox')
        }

        // Setup
        this.resource = this.resources.items.foxModel

        this.setModel()
        this.setAnimation()
    }


    setModel()  
    {
        this.model = this.resource.scene

        //Positions & Responsiveness
        if(this.experience.sizes.width < 991) {
            this.model.position.set(4, 0, -10)

            //animation (movement of the whale)
            gsap.to(this.model.position, { duration: 45, x: -5, y: 0, z: -2, ease: 'none', 
                onComplete: () => {
                gsap.fromTo(this.model.position, {  x: 5, y: 0, z: -8,}, { duration: 45, x: -9, y: 0, z: 3, repeat: -1, ease: 'none', })
                }
             })
        } else {
            this.model.position.set(6.5, 0, -10)

               gsap.to(this.model.position, { duration: 45, x: -13, y: 0, z:10, ease: 'none', onComplete: () => {
                gsap.fromTo(this.model.position, {  x: 6, y: 0, z: -18,}, { duration: 45, x: -12, y: 0, z: 3, repeat: -1, ease: 'none', })
             } })
             
        }

        this.model.scale.set(0.5, 0.5, 0.5)
        this.model.rotation.y = Math.PI * 0.8
        //console.log(this.model)
        //this.model.position.set(0, 0, 0)
        
        this.scene.add(this.model)

        this.model.traverse((child) =>
        {
            if(child instanceof THREE.Mesh)
            {
                child.castShadow = true
                //10 percent chance
                // if(Math.random() < 0.1) {
                //     child.material.map = null
                // }
                
                //console.log(child)
            }
        })


        

    }

    setAnimation()
    {
        this.animation = {}
        
        this.animation.mixer = new THREE.AnimationMixer(this.model)
        this.animation.actions = {}
        this.animation.actions.swim = this.animation.mixer.clipAction(this.resource.animations[0])
        // this.animation.actions.walking = this.animation.mixer.clipAction(this.resource.animations[1])
        // this.animation.actions.running = this.animation.mixer.clipAction(this.resource.animations[2])

        this.animation.actions.current = this.animation.actions.swim
        this.animation.actions.current.play()

        this.animation.play = (name) =>
        {
            const newAction = this.animation.actions[name]
            const oldAction = this.animation.actions.current

            newAction.reset()
            newAction.play()
            newAction.crossFadeFrom(oldAction, 1)

            this.animation.actions.current = newAction
        }

        if(this.debug.active)
        {
            const debugObject = {
                playSwim: () => { this.animation.play('swimming') },
                // playWalking: () => { this.animation.play('walking') },
                // playRunning: () => { this.animation.play('running') }
            }
            this.debugFolder.add(debugObject, 'playSwim')
            // this.debugFolder.add(debugObject, 'playWalking')
            // this.debugFolder.add(debugObject, 'playRunning')
        }


    }

    update() {
        this.animation.mixer.update(this.time.delta * 0.00009)
        // this.model.position.x -= 0.005
        // this.model.position.z -= 0.005
         this.model.position.y = Math.sin(this.time.elapsed * 0.0002) * 0.25
        // console.log(Math.sin(this.time.elapsed * 0.0001) * 0.1) * 0.25
        //console.log(this.time)
        
    }

}