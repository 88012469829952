import * as THREE from 'three'
import Experience from "./Experience";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

export default class Camera 
{
    constructor() {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas
        this.setInstance()
       // this.setControls()
        //this.setAxisHelper()
    }


    setInstance()
    {
        this.instance = new THREE.PerspectiveCamera(35, this.sizes.width / this.sizes.height, 0.1, 3000)
        //this.instance = new THREE.PerspectiveCamera(35, this.sizes.width / this.sizes.height, 0.1, 3000)
        //this.instance.position.set(6, 4, 8)
        //this.instance.position.set(0, 0, 8)
        this.instance.position.set(0, 0, 8)
        this.scene.add(this.instance)   

    }

    setAxisHelper() {
        this.axesHelper = new THREE.AxesHelper( 5 );
        this.scene.add( this.axesHelper ); 
    }

    setControls() {
        this.controls = new OrbitControls(this.instance, this.canvas)
        this.controls.enableDamping = true
    }

    resize() {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }

    update() {
       // this.controls.update()
    }

}
