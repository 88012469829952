import * as THREE from 'three'
import Experience from '../Experience.js'
import vertexShader from '../../Shaders/WaterParticles/vertex.glsl'
import fragmentShader from '../../Shaders/WaterParticles/fragment.glsl'
//import Renderer from '../Renderer.js'
import Camera from '../Camera.js'
import gsap from 'gsap'
import { Vector3 } from 'three'


export default class WaterParticles {

    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.debug = this.experience.debug
        this.camera = this.experience.camera

       
        
        if(this.debug.active)
        {
           this.debugFolder = this.debug.ui.addFolder('WaterParticles')
        }
        
         // Setup
         this.setGeometry()
         this.setMaterial()
         this.setMesh()
         this.setRayCasterEvent() 
         //this.animate() 
         this.update()
         
         
    }
    
    setGeometry() {

        const particlesCount = 100
        
        const positions = new Float32Array(particlesCount * 3)
    
        const colors = new Float32Array(particlesCount * 3)
        const scales = new Float32Array(particlesCount * 1)
        const opacitys = new Float32Array(particlesCount * 1)
        const rotations = new Float32Array(particlesCount * 1)

        for(let i = 0; i < particlesCount; i++)
            {
                positions[i * 3 + 0] = (Math.random() - 0.5) * 14
                positions[i * 3 + 1] = (Math.random() - 0.5) * 10
                positions[i * 3 + 2] = (Math.random() - 0.5) * 10


          

                scales[i] = Math.random() 
                opacitys[i] = Math.random() - 0.45
                colors[i] = Math.random()
                rotations[i] = Math.random()

            }


        this.particlesGeometry = new THREE.BufferGeometry()

        this.particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
        
        this.particlesGeometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))
        this.particlesGeometry.setAttribute('aScale', new THREE.BufferAttribute(scales, 1))
        this.particlesGeometry.setAttribute('aOpac', new THREE.BufferAttribute(opacitys, 1))
        this.particlesGeometry.setAttribute('aRot', new THREE.BufferAttribute(rotations, 1))

    }

    setMaterial() {

       
      
        this.PARTICLE_SIZE = 40
        this.INTERSECTED = null
        this.particlematerial = new THREE.ShaderMaterial( {
            transparent: true,
            depthWrite: false,
            blending: THREE.AdditiveBlending,
            vertexColors: true,
            uniforms: {
                uSize: { value: 50  * this.experience.sizes.pixelRatio },
                uMouse: { value: new THREE.Vector3() },
                //color: { value: new THREE.Color( 0xffffff ) },
                //Could potentially add slow movement very slow
                uTime: { value: 0 },
                
            },
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            side: THREE.DoubleSide,
        } )

        //console.log(this.particlematerial)

    }

    setMesh() {
            this.particles = new THREE.Points(this.particlesGeometry, this.particlematerial)
            this.particles.position.set(0, 0, -5)
            this.scene.add(this.particles)
    }

    setRayCasterEvent() {

        this.mesh = new THREE.Mesh(
            new THREE.PlaneBufferGeometry(15,15,10,10),
            new THREE.MeshBasicMaterial({color: 0xff0000, wireframe: true})
        )
        this.mesh.position.set(0, 0, 0)
        //this.scene.add(this.mesh)

        this.test = new THREE.Mesh(
            new THREE.SphereBufferGeometry(0.3, 10, 10),
            new THREE.MeshBasicMaterial({color: 0xff0000, wireframe: true})
        )
        

        //this.scene.add(this.test)


                /**
                * Cursor
                */
                this.cursor = {}
                this.cursor.x = 0
                this.cursor.y = 0
                this.raycaster = new THREE.Raycaster();
                this.point = new THREE.Vector3()

                window.addEventListener('mousemove', (event) =>
                {
                    this.cursor.x = ( event.clientX / this.experience.sizes.width ) * 2 - 1
                    this.cursor.y = - (event.clientY / this.experience.sizes.height) * 2 + 1
                   // console.log(this.cursor.x, this.cursor.y)
                    //console.log(this.cursor.raycaster.ray.direction)
                    //this.raycaster.setFromCamera(this.mouse, this.experience.camera)
                    //console.log(this.cursor)
                    this.raycaster.setFromCamera(this.cursor, this.camera.instance)
                    const intersects = this.raycaster.intersectObject(this.mesh)
                    //console.log(this.raycaster)
                    if(intersects[0]) {
                        //console.log(intersects[0].point)
                        this.test.position.copy(intersects[0].point)
                        this.point.copy(intersects[0].point)
                    }
                                    

                })

                

                



    }


    animate() {
        this.particles.position.x = gsap.utils.random(-5, 5)
        gsap.to(this.particles.position, {duration: 4, y: 6, repeatRefresh: true, ease: "linear", onComplete: () => 
            { 
                gsap.fromTo(
                    this.particles.position, 
                    {x: "random(-5, 5)", y: -5, z: "random(-3, 0)"},
                    {y: 6, repeatRefresh: true, duration: 4, repeat: -1, ease: "linear", repeatDelay: 3}
                    )
         }
        })
    }

    update() {
        this.particles.material.uniforms.uTime.value = this.experience.time.elapsed
        this.particles.rotateOnAxis(new Vector3(0, 1, 0), 0.001)
        // Rotate the particles around the Y axis'
        //this.particles.rotation.y += 0.01


        this.particles.material.uniforms.uMouse.value = this.point
        //this.cursor.raycaster.setFromCamera(this.cursor, this.camera.instance)
        
       
       
        
      


    }
    


}