import * as THREE from 'three'
import Experience from '../Experience.js'
import vertexShader from '../../Shaders/ParticlesBubbles/vertex.glsl'
import fragmentShader from '../../Shaders/ParticlesBubbles/fragment.glsl'
//import Renderer from '../Renderer.js'
import gsap from 'gsap'


export default class Bubbles {

    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.debug = this.experience.debug
      

       

        if(this.debug.active)
        {
           this.debugFolder = this.debug.ui.addFolder('Bubbles')
        }
        
         // Setup
         this.setGeometry()
         this.setMaterial()
         this.setMesh()
         this.animate() 
         this.update()
    }
    
    setGeometry() {

        const particlesCount = 30
        const positions = new Float32Array(particlesCount * 3)
       
        const colors = new Float32Array(particlesCount * 3)
        const scales = new Float32Array(particlesCount * 1)
        const opacitys = new Float32Array(particlesCount * 1)


        for(let i = 0; i < particlesCount; i++)
            {
                positions[i * 3 + 0] = (Math.random() - 0.5) * 0.4 
                positions[i * 3 + 1] = (Math.random() - 0.5) * 2
                positions[i * 3 + 2] = (Math.random() - 0.5) * 0.4

                scales[i] = Math.random()
                opacitys[i] = Math.random()
                colors[i] = Math.random()

            }


        this.particlesGeometry = new THREE.BufferGeometry()

        this.particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
        this.particlesGeometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))
        this.particlesGeometry.setAttribute('aScale', new THREE.BufferAttribute(scales, 1))
        this.particlesGeometry.setAttribute('aOpac', new THREE.BufferAttribute(opacitys, 1))


    }

    setMaterial() {

        this.particlematerial = new THREE.ShaderMaterial( {
            transparent: true,
            depthWrite: false,
            blending: THREE.AdditiveBlending,
            vertexColors: true,
            uniforms: {
                uSize: { value: 100 * this.experience.sizes.pixelRatio },
                color: { value: new THREE.Color( 0xffffff ) },
                //Could potentially add slow movement very slow
                uTime: { value: 0 },
            },
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
        } )

    }

    setMesh() {
            this.particles = new THREE.Points(this.particlesGeometry, this.particlematerial)
            this.particles.position.set(0, -5, 0)
        
            this.scene.add(this.particles)
    }

    animate() {
        this.particles.position.x = gsap.utils.random(-5, 5)
        gsap.to(this.particles.position, {duration: 4, y: 6, repeatRefresh: true, ease: "linear", onComplete: () => 
            { 
                gsap.fromTo(
                    this.particles.position, 
                    {x: "random(-5, 5)", y: -5, z: "random(-3, 0)"},
                    {y: 6, repeatRefresh: true, duration: 4, repeat: -1, ease: "linear", repeatDelay: 3}
                    )
         }
        })
    }
    update() {
        this.particles.material.uniforms.uTime.value = this.experience.time.elapsed
    }
    


}