import Experience from '../Experience.js'
import Environment from './Environment.js'
import Floor from './Floor.js'
import Fox from './Fox.js'
import Cube from './Cube.js'
import Water from './Water.js'
import SunRays from './SunRays.js'
import Bubbles from './Bubbles.js'
import WaterParticles from './WaterParticles.js'
import WaterSurface from './WaterSurface.js'
import * as THREE from 'three'
import gsap from 'gsap'
import Fish from './Fish.js'
import Shark from './Shark.js'
import ClownFish  from './ClownFish.js'

export default class World
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources


    
        //this.setLoadingScreen() 
        // this.resources.on('progress', (progress) =>
        // {
        //     //console.log(progress * 100)
        //     this.loadingBarElement = document.querySelector('.loader')   

        //     this.loadingprogress = 100 - (progress * 100)

        //     this.loadingBarElement.style.height = `${this.loadingprogress}%`

        //     //console.log(progress)
        // })
        // Wait for resources
        this.resources.on('ready', () =>
        {
            this.destroyLoadingScreen()
            // Setup
            //this.floor = new Floor()
            this.fox = new Fox()
            this.shark = new Shark()
            this.ClownFish = new ClownFish()
            this.environment = new Environment()
            this.water = new Water()
            //this.Cube = new Cube()

            //Sunrays Not sure if this is correct way to do it
            // this.sunRays = new SunRays(-3, -2)
            // this.sunRays = new SunRays(-1.5, -1)
            // this.sunRays = new SunRays(-0.5, 0.5)
            // this.sunRays = new SunRays(1, 1.5)
            // this.sunRays = new SunRays(2.0, 2.5)
            // this.sunRays = new SunRays(3, 3.5)
            // this.sunRays = new SunRays(4, 4.5)

            this.Bubbles = new Bubbles()

            this.WaterParticles = new WaterParticles()

            //Water Surface
            this.WaterSurface = new WaterSurface()

            //Fish
            //this.fish = new Fish()

            
           
        })
    }


    setLoadingScreen() {

    //     /**
    //      * Overlay
    //      */
    //  this.overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
    //  this.overlayMaterial = new THREE.ShaderMaterial({
    //     transparent: true,
    //     uniforms:
    //     {
    //         uAlpha: { value: 1 }
    //     },
    //      vertexShader: `
    //          void main()
    //          {
    //              gl_Position = vec4(position, 1.0);
    //          }
    //      `,
    //      fragmentShader: `
    //     uniform float uAlpha;

    //     void main()
    //     {
    //         gl_FragColor = vec4(0.0, 0.23, 0.47, uAlpha);
    //     }
    // `, 
    // side: THREE.FrontSide,
    
    
    //  })
     //this.overlay = new THREE.Mesh(this.overlayGeometry, this.overlayMaterial)
     //this.scene.add(this.overlay)
     this.loadingBarElement = document.querySelector('.loading-bar')   


    }


    destroyLoadingScreen() {
        this.loadingBarElement = document.querySelector('.loading-bar') 
        window.setTimeout(() =>
        {
            
            //gsap.to(this.overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0, delay: 1 })
            //gsap.to(this.overlay.position, { duration: 3x, 'x': -15, delay: 0 })
            //this.loadingBarElement.style.transform = `scaleX(200px)`
            this.loadingBarElement.classList.add('ready')
            //this.loadingBarElement.classList.add('ended')
            // loadingBarElement.style.transform = ''
        }, 500)

    }


    update()
    {
        if(this.fox) {
            this.fox.update()
        }

        if(this.WaterParticles) {
            this.WaterParticles.update()
        }

        if(this.WaterSurface) {
            this.WaterSurface.update()
        }

        if(this.Bubbles) {
            this.Bubbles.update()
        }

        if(this.fish) {
            this.fish.update()
        }

        if(this.shark) {
            this.shark.update()
        }

        if(this.ClownFish) {
            this.ClownFish.update()
        }


            
    }

}